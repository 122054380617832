import React, { useRef, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { Amplify, Analytics, API, graphqlOperation } from "aws-amplify";
// import {
//   createClient,
//   updateClient,
//   getClient,
//   listClients,
// } from "./graphql/mutations";

// import { createTodo } from "./graphql/mutations";
import { createPatientLead } from "./graphql/mutations";

// import amplifyconfig from "./amplifyconfiguration.json";
import awsconfig from "./aws-exports";

// import { record } from "aws-amplify/analytics";
// import { configureAutoTrack } from "aws-amplify/analytics";

Amplify.configure(awsconfig);
// const todo = { name: "My first todo", description: "Hello world!" };

// configureAutoTrack({
//   // REQUIRED, turn on/off the auto tracking
//   enable: true,
//   // REQUIRED, the event type, it's one of 'event', 'pageView' or 'session'
//   type: "session",
//   // OPTIONAL, additional options for the tracked event.
//   options: {
//     // OPTIONAL, the attributes of the event
//     attributes: {
//       customizableField: "attr",
//     },
//   },
// });

Analytics.autoTrack("session", {
  // tutorial: https://docs.amplify.aws/javascript/prev/build-a-backend/more-features/analytics/auto-track-sessions/

  // REQUIRED, turn on/off the auto tracking
  enable: true,
  eventName: "pageView",
  // OPTIONAL, the attributes of the event, you can either pass an object or a function
  // which allows you to define dynamic attributes
  attributes: {
    attr: "attr",
  },
  // when using function
  // attributes: () => {
  //    const attr = somewhere();
  //    return {
  //        myAttr: attr
  //    }
  // },
  // OPTIONAL, the service provider, by default is the Amazon Pinpoint
  provider: "AWSPinpoint",
});

// Create a new client
// const createNewClient = async (clientData) => {
//   try {
//     const newClient = await API.graphql(
//       graphqlOperation(createClient, { input: clientData })
//     );
//     console.log("New client createdssss:", newClient);
//     return newClient.data.createClient;
//   } catch (error) {
//     console.error("Error creating client:", error);
//     throw error;
//   }
// };

// Update an existing client
// const updateExistingClient = async (clientId, updatedClientData) => {
//   try {
//     const updatedClient = await API.graphql(
//       graphqlOperation(updateClient, {
//         input: { id: clientId, ...updatedClientData },
//       })
//     );
//     console.log("Client updated:", updatedClient.data.updateClient);
//     return updatedClient.data.updateClient;
//   } catch (error) {
//     console.error("Error updating client:", error);
//     throw error;
//   }
// };

// // Get a specific client by ID
// const getClientById = async (clientId) => {
//   try {
//     const client = await API.graphql(
//       graphqlOperation(getClient, { id: clientId })
//     );
//     console.log("Client retrieved:", client.data.getClient);
//     return client.data.getClient;
//   } catch (error) {
//     console.error("Error getting client:", error);
//     throw error;
//   }
// };

// // List all clients
// const getAllClients = async () => {
//   try {
//     const clients = await API.graphql(graphqlOperation(listClients));
//     console.log("Clients list:", clients.data.listClients);
//     return clients.data.listClients;
//   } catch (error) {
//     console.error("Error listing clients:", error);
//     throw error;
//   }
// };

function App() {
  // Create a ref to access the iframe element
  const iframeRef = useRef(null);

  // Function to move the content inside the iframe
  const moveContent = (direction) => {
    const iframe = iframeRef.current;

    if (!iframe) return; // Ensure the iframe ref is available

    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const contentElement = iframeDocument.getElementById("contentToMove");

    if (!contentElement) return; // Ensure the content element is found

    const currentTop = parseInt(contentElement.style.top) || 0;

    // Determine the direction of movement based on the 'direction' parameter
    const newTop = direction === "up" ? currentTop - 100 : currentTop + 100;

    // Update the top style of the content element
    contentElement.style.top = `${newTop}px`;
  };

  // useEffect(() => {
  //   moveContent("up");
  // }, []);

  // const [formData, setFormData] = useState({
  //   client_name: "aaaa",
  //   client_role: "aaaa",
  //   client_organization: "aaaa",
  //   client_email: "aaaa",
  //   client_number: "aaaa",
  //   client_type: "aaaa",
  //   client_roles: ["aaaa"],
  //   client_needs: "aaaa",
  // });

  const leadData = {
    lead_name: "aaaa", // Make sure this matches your schema field name (e.g., lead_name)
    lead_role: "aaaa",
    lead_organization: "aaaa",
    lead_email: "aaaa",
    lead_number: "aaaa",
    lead_type: "aaaa",
    lead_roles: ["aaaa"], // This should be an array of strings (if defined as [String] in schema)
    lead_needs: "aaaa",
  };

  const handleSubmit = async () => {
    try {
      console.log(leadData);
      // const newClient = await createNewClient(formData); // Call the createNewClient function
      const newLead = await API.graphql(
        graphqlOperation(createPatientLead, { input: leadData })
      );
      console.log("New client created:", newLead);
      // Optionally, reset the form or update state to indicate success
    } catch (error) {
      console.error("Error creating client:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  return (
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
    <div>
      {/* <div className="App-Header"></div> */}
      <div className="App">
        {/* <button onClick={handleSubmit}>{"Send"}</button> */}
        <iframe
          className="iframe"
          ref={iframeRef}
          title="iframe"
          src={"https://accountspayable43.wixsite.com/my-althea"}
          // width="100%"
          // height="100%"
        />
      </div>
    </div>
  );
}

export default App;
