/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPatientLead = /* GraphQL */ `
  mutation CreatePatientLead(
    $input: CreatePatientLeadInput!
    $condition: ModelPatientLeadConditionInput
  ) {
    createPatientLead(input: $input, condition: $condition) {
      id
      lead_name
      lead_role
      lead_organization
      lead_email
      lead_number
      lead_type
      lead_roles
      lead_needs
      createdAt
      updatedAt
    }
  }
`;
export const updatePatientLead = /* GraphQL */ `
  mutation UpdatePatientLead(
    $input: UpdatePatientLeadInput!
    $condition: ModelPatientLeadConditionInput
  ) {
    updatePatientLead(input: $input, condition: $condition) {
      id
      lead_name
      lead_role
      lead_organization
      lead_email
      lead_number
      lead_type
      lead_roles
      lead_needs
      createdAt
      updatedAt
    }
  }
`;
export const deletePatientLead = /* GraphQL */ `
  mutation DeletePatientLead(
    $input: DeletePatientLeadInput!
    $condition: ModelPatientLeadConditionInput
  ) {
    deletePatientLead(input: $input, condition: $condition) {
      id
      lead_name
      lead_role
      lead_organization
      lead_email
      lead_number
      lead_type
      lead_roles
      lead_needs
      createdAt
      updatedAt
    }
  }
`;
